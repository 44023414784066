import CompanyComponentVue from "@/components/master/features/Company/CompanyComponent.vue";
import FinancialYearComponentVue from "@/components/master/features/FinancialYear/FinancialYearComponent.vue";
import ForgetPasswordComponent from "@/components/master/features/ForgetPassword/ForgetPasswordComponent.vue";
import LoginComponent from "@/components/master/features/Login/LoginComponent.vue";
import ModuleComponent from "@/components/master/features/Module/ModuleComponent.vue";
import NotFoundComponent from "@/components/master/features/NotFound/NotFoundComponent.vue";
import PageComponent from "@/components/master/features/Page/PageComponent.vue";
import PermissionComponent from "@/components/master/features/Permission/PermissionComponent.vue";
import RecoverPasswordComponentVue from "@/components/master/features/RecoverPassword/RecoverPasswordComponent.vue";
import RegisterComponent from "@/components/master/features/Register/RegisterComponent.vue";
import SubscriptionComponentVue from "@/components/master/features/Subscription/SubscriptionComponent.vue";
import SyncLogComponentVue from "@/components/master/features/SyncLog/SyncLogComponent.vue";
import UserComponent from "@/components/master/features/User/UserComponent.vue";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import { authGuard } from "../services/authGuard";
import fortnoxPageRoutes from "./fortnox.routes";
import powerOfficePageRoutes from "./poweroffice.routes";
import resultSheetRoutes from "./resultsheet.routes";

const routes: Array<RouteRecordRaw> = [
	{
		path: "",
		redirect: "/home",
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/not-found",
	},
	{
		path: "/not-found",
		name: "Not Found",
		component: NotFoundComponent,
	},
	{
		path: "/home",
		name: "Home",
		component: () => import("@/components/master/features/Home/HomeComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/subscription",
		name: "Subscription",
		component: SubscriptionComponentVue,
		meta: { requiresAuth: true },
	},
	{
		path: "/user",
		name: "User",
		component: UserComponent,
		meta: { requiresAuth: true },
	},
	{
		path: "/permission",
		name: "Permission",
		component: PermissionComponent,
		meta: { requiresAuth: true },
	},
	{
		path: "/module",
		name: "Module",
		component: ModuleComponent,
		meta: { requiresAuth: true },
	},
	{
		path: "/register",
		name: "Register",
		component: RegisterComponent,
	},
	{
		path: "/login",
		name: "Login",
		component: LoginComponent,
	},
	{
		path: "/forget-password",
		name: "Forget Password",
		component: ForgetPasswordComponent,
	},
	{
		path: "/company",
		name: "Fortnox Company",
		component: CompanyComponentVue,
		meta: { requiresAuth: true },
	},
	{
		path: "/page",
		name: "Page",
		component: PageComponent,
		meta: { requiresAuth: true },
	},
	{
		path: "/recoverPassword",
		name: "RecoverPassword",
		component: RecoverPasswordComponentVue,
		meta: { requiresAuth: false },
	},
	{
		path: "/financialYear",
		name: "Financial Year",
		component: FinancialYearComponentVue,
		meta: { requiresAuth: true },
	},
	{
		path: "/syncLog",
		name: "Sync Log",
		component: SyncLogComponentVue,
		meta: { requiresAuth: true },
	},
	{
		path: "/page-build",
		name: "Page Build",
		component: () => import("@/components/pagebuilder/view/PageBuildComponent.vue"),
		meta: { requiresAuth: false },
	},
	{
		path: "/page-manage",
		name: "Page Manage",
		component: () => import("@/components/pagebuilder/view/PageManageComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/data-sync-configuration",
		name: "Data Sync Configuration",
		component: () => import("@/components/master/features/DataSyncConfiguration/DataSyncConfigurationComponent.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/app-log",
		name: "Application Log",
		component: () => import("@/components/master/features/CentralAppLog/CentralAppLogComponent.vue"),
		meta: { requiresAuth: true },
	},
	...resultSheetRoutes,
	...fortnoxPageRoutes,
	...powerOfficePageRoutes,
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		authGuard(to, from, next);
	} else {
		next();
	}
});

export default router;
