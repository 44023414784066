import AccountTransactionComponent from "@/components/poweroffice/features/Account/AccountTransactionComponent.vue";
import CurrencyComponent from "@/components/poweroffice/features/Account/CurrencyComponent.vue";
import ClientComponent from "@/components/poweroffice/features/Client/ClientComponent.vue";
import ClientContactComponent from "@/components/poweroffice/features/Client/ClientContactComponent.vue";
import CompanyComponent from "@/components/poweroffice/features/Company/CompanyComponent.vue";
import ContactGroupComponent from "@/components/poweroffice/features/Contact/ContactGroupComponent.vue";
import ContactPersonComponent from "@/components/poweroffice/features/Contact/ContactPersonComponent.vue";
import CustomDimensionComponent from "@/components/poweroffice/features/CustomDimension/CustomDimensionComponent.vue";
import CustomDimensionSettingComponent from "@/components/poweroffice/features/CustomDimension/CustomDimensionSettingComponent.vue";
import CustomerComponent from "@/components/poweroffice/features/Customer/CustomerComponent.vue";
import CustomerSubLedgerEntryComponent from "@/components/poweroffice/features/Customer/CustomerSubLedgerEntryComponent.vue";
import SupplierComponent from "@/components/poweroffice/features/Supplier/SupplierComponent.vue";
import SupplierSubLedgerEntryComponent from "@/components/poweroffice/features/Supplier/SupplierSubLedgerEntryComponent.vue";
import { RouteRecordRaw } from "vue-router";

const routePrefix = "/po/";
const componentPrefix = "@/components/poweroffice/features";

const powerOfficePageRoutes: RouteRecordRaw[] = [
  {
    path: `${routePrefix}company`,
    name: "Company",
    component: CompanyComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}currency`,
    name: "Currency",
    component: CurrencyComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}transaction`,
    name: "Account Transactions",
    component: AccountTransactionComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}customer`,
    name: "Customer",
    component: CustomerComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}customer-subledger`,
    name: "Customer Sub Ledger",
    component: CustomerSubLedgerEntryComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}client`,
    name: "Client",
    component: ClientComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}client-contact`,
    name: "Client Contacts",
    component: ClientContactComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}contact-group`,
    name: "Contact Group",
    component: ContactGroupComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}contact-person`,
    name: "Contact Person",
    component: ContactPersonComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}dimension`,
    name: "Custom Dimension",
    component: CustomDimensionComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}dimension-setting`,
    name: "Custom Dimension Setting",
    component: CustomDimensionSettingComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}supplier`,
    name: "Supplier",
    component: SupplierComponent,
    meta: { requiresAuth: true },
  },
  {
    path: `${routePrefix}supplier-subledger`,
    name: "Supplier Sub Ledger",
    component: SupplierSubLedgerEntryComponent,
    meta: { requiresAuth: true },
  },
];

export default powerOfficePageRoutes;
