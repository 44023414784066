

import authService from '@/services/authService';
import { computed, defineComponent, ref } from 'vue';
import modal from '../common/modalManage';
import { User } from '../master/features/User/User.model';
import agent from '@/services/agent';
import { PutUserProfileResponse } from '../common/model';
import toasterService from '@/services/toasterService';
import { LoginDTO } from '@/services/models/login';

export default defineComponent({
    components:{
    },
    props: {
        multilang: {
            type: Object,
            default: () => ({ se: false, en: false }),
        },
        language: String
    },
    emits: ['langchange','logout'],
    setup(props, ctx) {

        const isUpdate = ref(false);

        const userProfile = ref({} as User);

        const author = computed(() => {
            return authService.getUserEmail();
        });

        function languageChangeEvent(code:string){
            ctx.emit('langchange',code);
        }

        function logOut(){
            ctx.emit('logout');
        }

        function onChangePassword(){
            modal.Open('changePasswordModal');
        }

        function onChangeProfileUser(){
            isUpdate.value = true;
            const fullName = authService.getFullName() as String | undefined | null;
            if(fullName){
                const first = String(fullName.split(" ").shift());
                const last = fullName.split(" ").filter((item,index) => index > 0).join(" ");
                userProfile.value.firstName = first;
                userProfile.value.lastName = last;
            }
            userProfile.value.email = authService.getUserEmail();
            modal.Open('userProfileModal');
        }

        async function onUpdateProfileUser(){
            const payload = {
                firstName:userProfile.value.firstName,
                lastName:userProfile.value.lastName,
                email:userProfile.value.email
            }
            const response = await agent.put<PutUserProfileResponse>(`v1/user/PutUserProfile`,payload);
            if(response && response.result){
                const getUser = authService.getJwtUser() as LoginDTO;
                getUser.name = `${userProfile.value.firstName} ${userProfile.value.lastName}`;
                authService.setJwtUser(getUser);
                toasterService.success(`User profile updated successfully!`);
                modal.Close('userProfileModal');
            }
        }

        function close(){
            modal.Close('userProfileModal');
        }

        return {
            props, ctx,languageChangeEvent,logOut,author,onChangePassword,onChangeProfileUser,isUpdate,userProfile,close,onUpdateProfileUser
        }
    },
});
