import router from "@/router/router";
import axios, { AxiosError, AxiosResponse } from "axios";
import authService from "./authService";
import { API_KEY } from "./constant";
import { GlobalResponse } from "./models/global-response.model";
import { ModuleBaseUrl } from "./models/login";
import preLoader from "./preLoader";
import toasterService from "./toasterService";

const module: ModuleBaseUrl | undefined = authService.getModulesBaseUrl();

export const masterServiceBaseURL = location.href.includes("localhost") ? API_KEY.BASE_LOCAL 
        : location.href.includes("clementine-dev") || location.href.includes("dev.numberskills") ? API_KEY.BASE_DEV 
        : location.href.includes("polite-desert-099c05a03.5") || location.href.includes("test.numberskills") ? API_KEY.BASE_TEST : API_KEY.BASE_PROD ;
      
export const resultSheetServiceBaseURL =
  module && module.resultSheet ? module.resultSheet : "";
export const fortnoxServiceBaseURL =
  module && module.fortnox ? module.fortnox : "";

export const pageBuilderServiceBaseURL =
module && module.pageBuilder ? module.pageBuilder : "";

export const powerOfficeServiceBaseURL = module && module.powerOffice ? module.powerOffice : "";

const setBaseURL = (baseUrl: string) => {
  axios.defaults.baseURL = baseUrl;
};

const getValidationErrorMessages = (errors:any)=>{
  const errorMessages: string[] = [];
  for (const field in errors) {
    // eslint-disable-next-line no-prototype-builtins
    if (errors.hasOwnProperty(field)) {
      errors[field].forEach((error:any) => {
        errorMessages.push(`${field}: ${error}`);
      });
    }
  }
  return errorMessages;
}

const redirectToLogin = ()=>{
  localStorage.clear();
  router.push("/login");
}

axios.interceptors.response.use(
  (response) => {
    preLoader.hide();
    return response;
  },
  (error: AxiosError) => {
    preLoader.hide();
    if (error && error.response) {
      const response:AxiosResponse<GlobalResponse> = error.response as AxiosResponse<GlobalResponse>;
      console.log({error});
      switch (response.status) {
        case 400:
          if (response.data.errors) {
            const errorMessages = getValidationErrorMessages(response.data.errors);
            toasterService.warn(`${errorMessages.map((x) => x).join(", ")}`);
          } else {
            toasterService.warn(`${response.data.errorMessage}`);
          }
          break;
        case 401:
          toasterService.success("Session expired!! Sign out automatically. Sign in again please");
          redirectToLogin();
          break;
        case 403:
          toasterService.error("Forbidden!!");
          break;
        case 404:
          toasterService.error("Not Found!!");
          break;
        case 405:
          toasterService.error("Method not allowed!!");
          break;
        case 500:
          if(response.data.errorMessage){
            toasterService.error(`${response.data.errorMessage}`);
          }else{
            toasterService.error(`Server Error!!${error.message}`);
          }
          break;
        default:
          toasterService.error(`${response.data.errorMessage}`);
          break;
      }
    }else if(error){
      toasterService.error(`${error.message??"Something wen't wrong"}`);
    }
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response && response.data;

const getHeaders = async () => {
  const token = authService.getToken();
  const isExpired = authService.isExpired(token);
  if (isExpired) {
    const response = await axios.post(API_KEY.POST_REFRESH(masterServiceBaseURL), { token: token });
    if (response && response.data) {
      authService.setToken(response.data.result);
      return { Authorization: `Bearer ${response.data.result}` };
    }else{
      redirectToLogin();
    }
  }
  return { Authorization: `Bearer ${token}` };
};

const agent = {
  setBaseUrl: (baseUrl: string) => setBaseURL(baseUrl),
  async get<T>(url: string, params: {} = {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    const headers = await getHeaders();
    return axios
      .get<T>(url, { params: params, headers: headers })
      .then(responseBody);
  },
  async post<T>(url: string, body: {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    const headers = await getHeaders();
    return axios
      .post<T>(url, body, { headers: headers })
      .then(responseBody);
  },
  async put<T>(url: string, body: {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    const headers = await getHeaders();
    return axios
      .put<T>(url, body, { headers: headers })
      .then(responseBody);
  },
  async delete<T>(url: string, params: {} = {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    const headers = await getHeaders();
    return axios
      .delete<T>(url, { data: params, headers: headers })
      .then(responseBody);
  },
  getAnonymous<T>(url: string, params: {} = {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    return axios
      .get<T>(url, { params: params, headers: {} })
      .then(responseBody);
  },
  postAnonymous<T>(url: string, body: {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    return axios.post<T>(url, body).then(responseBody);
  },
  putAnonymous<T>(url: string, body: {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    return axios.put<T>(url, body).then(responseBody);
  },
  deleteAnonymous<T>(url: string, params: {} = {},isLoaderShow:boolean = true) {
    if(isLoaderShow) preLoader.show();
    return axios
      .delete<T>(url, { params: params, headers: {} })
      .then(responseBody);
  },
  refresh<T>() {
    const token = authService.getToken();
    return axios
      .post<T>(API_KEY.POST_REFRESH(masterServiceBaseURL), { token: token })
      .then(responseBody);
  },
};

export default agent;
